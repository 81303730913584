import _ from 'lodash';
window._ = _;

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

import axios from 'axios';
window.axios = axios;

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */

import Echo from 'laravel-echo';

import Pusher from 'pusher-js';
window.Pusher = Pusher;

if (!Date.prototype.toISODate) {
    Date.prototype.toISODate = function() {
        return this.getFullYear() + '-' +
            ('0'+ (this.getMonth()+1)).slice(-2) + '-' +
            ('0'+ this.getDate()).slice(-2);
    };
}

$(document).ready(function() {
    /**
     * Fix Bootstrap not blocking scrolling on background page when a modal is opened.
     */
    const modals = document.querySelectorAll('.modal');


    modals.forEach(modal => {
        let disableScrolling = (element) => {
            element.style.overflow = 'hidden';
        };
        let enableScrolling = (element) => {
            element.style.overflow = 'auto';
        };

        $(modal).on('show.bs.modal', event => {
            disableScrolling(document.documentElement);
        });

        modal.addEventListener('hidden.bs.modal', event => {
            enableScrolling(document.documentElement);
        });
    });

    /**
     * ViLooker Pace Data open modal clicked.
     */
    $('[data-bs-target="#paceDataGrades"]:not(.viLookerPaceDataTriggerStudent):not(.manage-assessments-trigger)').on('click', (event) => {
        let subjectNameEl = event.target.parentElement.querySelector('.subject-name');
        let subjectName = subjectNameEl ? subjectNameEl.innerText : null;
        let studentEmail = event.target.dataset['studentEmail'];

        const lookerEvent = new CustomEvent('vi-looker.open', {
            detail: {
                studentEmail,
                subjectName,
                id: 'assessment-submissions',
                openGradingQueries: true,
            }
        });

        // Dispatch the event.
        document.body.dispatchEvent(lookerEvent);
    });

    $('[data-bs-target^="#weekly-summary"]').on('click', (event) => {
        let lookerId = event.target.dataset['bsTarget'];
        let studentEmail = event.target.dataset['studentEmail'];

        const lookerEvent = new CustomEvent('vi-looker.open', {
            detail: {
                studentEmail,
                id: lookerId,
            }
        });

        // Dispatch the event.
        document.body.dispatchEvent(lookerEvent);
    });

    /**
     * ViLooker Assignments due this week open modal clicked.
     */
     $('[data-bs-target="#upcoming-assignments"]').on('click', (event) => {
        // console.log('open modal trigger clicked')
        let studentEmail = event.target.dataset['studentEmail'];

        const lookerEvent = new CustomEvent('vi-looker.open', {
            detail: {
                studentEmail,
                id: "upcoming-assignments",
            }
        });

        // Dispatch the event.
        document.body.dispatchEvent(lookerEvent);
    });

    /**
     * ViLooker Pace Data open modal clicked.
     * Trigger event to select the correct exams.
     */
    $('[data-bs-target="#examModal"]').on('click', (event) => {
        let studentEmail = event.target.dataset['studentEmail'];

        const lookerEvent = new CustomEvent('vi-looker.open', {
            detail: {
                studentEmail,
                id: 'looker-exams'
            }
        });

        // Dispatch the event.
        document.body.dispatchEvent(lookerEvent);
    });

    $('.viLookerPaceDataTriggerStudent').on('click', function(event) {
        let subjectName = this.dataset['viLookerSubjectName']; // data-vi-looker-subject-name
        let studentEmail = this.dataset['viLookerStudentEmail'];

        const lookerEvent = new CustomEvent('vi-looker.open', {
            detail: {
                subjectName,
                studentEmail,
                id: 'assessment-submissions',
            }
        });

        // Dispatch the event.
        document.body.dispatchEvent(lookerEvent);
    });

    const url = new URL(window.location.href);
    const pathname = url.pathname;

    if (pathname.endsWith('students/grading-query')) {
        let element = document.querySelector('[data-bs-target="#examModal"]');
        let studentEmail = element.dataset.studentEmail;

        const lookerEvent = new CustomEvent('vi-looker.open', {
            detail: {
                studentEmail,
                id: 'assessment-submissions',
            }
        });

        document.body.dispatchEvent(lookerEvent);

        $('#paceDataGrades').modal('show');
    } else if (pathname.endsWith('guardians/grading-query')) {
        $('#gradingQueryNoticeModal').modal('show');
    }

    let manageAssessmentsTrigger = document.querySelector('.manage-assessments-trigger');

    if (manageAssessmentsTrigger) {
        manageAssessmentsTrigger.onclick = (event) => {
            let studentEmail = event.target.parentElement.dataset.studentEmail;

            const lookerEvent = new CustomEvent('vi-looker.open', {
                detail: {
                    studentEmail,
                    openGradingQueries: true,
                    id: 'assessment-submissions',
                }
            });

            document.body.dispatchEvent(lookerEvent);
        };
    }
});
